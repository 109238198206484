@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@vaultinum/vaultinum-sdk/dist/style.css";

@layer components {
    .bg-auth {
        background-image: url("./assets/img/bg-auth.svg");
    }
}

.ant-tabs-content {
    height: 100%;
}
